import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/PackshotStill_Magnifika.jpg"
import { navigate } from "gatsby-link"
const fair = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Magnifika." image={image} />
    <div className="result" id="1">
      <h3>Olet hiljainen ajattelija. </h3>
      <p>
        Havainnointikykysi on huippuluokkaa ja tuntosarvesi ovat aina valppaina.
        Olet monesti hahmottanut ratkaisun jo ennen kuin useimmat ovat
        käsittäneet kysymystä. Et pidä itsestäsi meteliä – päinvastoin: turha
        häly turhauttaa ja ahdistaa sinua. 
        <br />
        <br />
        Kahvisi on Löfbergs Magnifika. Kevyesti tummapaahtoinen, erittäin
        tasapainoinen.
      </p>
      <StaticImage
        src="../assets/PackshotStill_Magnifika.jpg"
        alt="Löfbergs Magnifika"
      />{" "}
      <Buttons
        quote="Kahvini on Löfbergs Magnifika. Kevyesti tummapaahtoinen, erittäin
              tasapainoinen."
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default fair
